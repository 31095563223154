import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[enterBlur]',
})
export class EnterBlurDirective {
  constructor(private el: ElementRef) { }
  @HostListener('keydown',['$event']) onMouseEnter(event: KeyboardEvent) {
    let tgt = <any>event.target;
    if (event.key == "Enter") {
      event.preventDefault();
      event.stopPropagation();
      tgt.blur();
    }
  }
}

@Directive({
  standalone: true,
  selector: '[focusSelect]',
})
export class FocusSelectDirective {
  constructor(private el: ElementRef) { }
  @HostListener('focus', ['$event']) onFocus(e: FocusEvent) {
    let element = e.target as HTMLElement;
    const range = document.createRange();
    range.setStart(element.firstChild, 0);
    range.setEnd(element.lastChild, element.lastChild.textContent.length);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }
}

@Directive({
  standalone: true,
  selector: '[focusOnShow]',
})
export class FocusOnShowDirective {
  constructor(private el: ElementRef) { 
    setTimeout(()=>el.nativeElement.focus());
  }

}