import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation} from '@angular/core';

import { ColDef, ColGroupDef, FilterModel, GridApi, GridOptions, GridReadyEvent, GridState, ICellRendererParams, PaginationState, RowHeightParams, SortModelItem, ValueFormatterParams } from 'ag-grid-community';
import { formatCurrency } from '@angular/common';

export interface AgTableState {
  filter: FilterModel;
  sort: SortModelItem[];
  pagination: PaginationState;
  top: number;
  rowSelection: string[];
}

@Component({
  selector: 'ag-datatable',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './ag-datatable.component.html',
  styleUrls: ['./ag-datatable.component.scss']
})
export class AgDatatableComponent implements OnInit {
  gridApi!: GridApi;

  _columns: ColDef[] =[];
  @Input() 
  get columns() {return this._columns}
  set columns(c: (ColDef | ColGroupDef)[]) {
    this._columns=[...c];
    this.updateRenderers();
  }
  @Input() rowData: any[] = [];
  @Input() selectionType: 'single' |'multiple' = 'single';
  @Input() isRowSelectable: (row)=>boolean = undefined;
  
  @Input() rowHeight: number = 40;
  @Input() getRowHeight?: (params: RowHeightParams) => number | undefined | null;

  @Output() view = new EventEmitter<any>();
  @Output() select = new EventEmitter<any>();
  @Output() state = new EventEmitter<any>();
  @Output() ready = new EventEmitter<any>();

  constructor(
  ) { 
  }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.gridApi.autoSizeAllColumns();
    this.gridApi.sizeColumnsToFit();
    this.ready.emit(this);
  }

  gridOptions: GridOptions;

  static readonly cellRenderers = {
    viewCell: (_: ICellRendererParams) => '<i class="fa fa-solid fa-eye fa-lg cursor-pointer"></i>',
    boolCell: (p: ICellRendererParams) => p.value ? 'Yes' : 'No',
    filterCell: (p: ICellRendererParams) => `${p.value}<i class="fa fa-solid fa-filter fa-lg cursor-pointer hover ps-2"></i>`,
    currencyCell: (p: ICellRendererParams) => formatCurrency(p.value ?? 0, 'en-us', '$'),
    linkCell: (p: ICellRendererParams) => {
      const href = p["href"] ? p["href"](p.data):"#";
      return `<a href="${href}" viewcell onclick="event.preventDefault()">${p.value}</a>`;
    },
  }

  static readonly valueFormatters = {
    currency: (params: ValueFormatterParams<any, number>) =>
      formatCurrency(params.value ?? 0, 'en-us', '$')
  };

  updateRenderers() {
    this._columns.forEach(c => {
      if (typeof c.cellRenderer === 'string') {
        if (AgDatatableComponent.cellRenderers[c.cellRenderer])
          c.cellRenderer = AgDatatableComponent.cellRenderers[c.cellRenderer];
        }
      if (typeof c.valueFormatter === 'string') {
        if (AgDatatableComponent.valueFormatters[c.valueFormatter])
          c.valueFormatter = AgDatatableComponent.valueFormatters[c.valueFormatter];
      }
    });
  }

  ngOnInit(): void {
    this.gridOptions = {
      context: {
        componentParent: this,
      },
      columnDefs: this._columns,
      rowData: this.rowData,
      rowHeight: this.rowHeight,
      getRowHeight: this.getRowHeight,
      rowSelection: this.selectionType,
      suppressRowClickSelection: true,
      isRowSelectable: this.isRowSelectable,
      onCellClicked: (event) => {
        this.onCellClicked(event);
      },
      onSelectionChanged: (event) => {
        this.select.emit(event.api.getSelectedRows());
      },
      onStateUpdated: (event) => {
        this.state.emit(event);
      },
      suppressDragLeaveHidesColumns: true,
      animateRows: false,
      cacheQuickFilter: true,
      defaultColDef: {
        sortable: true,
        editable: false,
        filter: true,
        floatingFilter: true,
        resizable: true,
        suppressHeaderMenuButton: true,
      },
    };
    this.updateRenderers();
  }

  onCellClicked(event: any) {
    if (event.event.target.tagName == 'I' && event.event.target.classList.contains('fa-filter')) {
      this.gridApi.setFilterModel({[event.column.colId]:{filterType:'text', type:'contains', filter:event.value}});
      return;
    }
    if (event.event.target.tagName == 'I' && event.event.target.classList.contains('fa-eye')) {
      this.view.emit(event);
      return;
    }
    if (event.event.target.tagName == 'A' && event.event.target.attributes["viewcell"]) {
      this.view.emit(event);
      return;
    }
  }

  getState(): AgTableState {
    const state: GridState = this.gridApi.getState();
    const row1 = this.gridApi.getFirstDisplayedRowIndex();
    return {
      filter: state?.filter?.filterModel,
      sort:state.sort?.sortModel,
      pagination: state?.pagination,
      top: row1,
      rowSelection: state?.rowSelection as string[],
    }
  }

  restoreState(p: AgTableState) {
    if (!p)
      return;
    if (p.filter)
      this.gridApi.setFilterModel(p.filter);
    if (p.sort)
      this.gridApi.applyColumnState({state:p.sort, defaultState: {sort: null}});
    if (p.pagination)
      this.gridApi.paginationGoToPage(p.pagination.page);
    if (p.top)
      this.gridApi.ensureIndexVisible(p.top,"top");
    if (p.rowSelection)
      p.rowSelection.forEach(r => this.gridApi.getRowNode(r).setSelected(true));
  }
}
