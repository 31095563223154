import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Params } from "@angular/router";
import { OrgUnit } from "src/app/core/models/org-unit";
import { Person } from "src/app/core/models/person";

@Component({
  selector: "lib-org-tree",
  templateUrl: "./org-tree.component.html",
  styleUrls: ["./org-tree.component.css"],
})
export class OrgTreeComponent {
  @Input() orgTree: OrgUnit;
  @Input() select: boolean = false;
  @Input() showFavUnits: boolean = false;
  @Output() onUnitExpand: EventEmitter<OrgUnit> = new EventEmitter();
  @Output() onUnitSelectChange: EventEmitter<OrgUnit> = new EventEmitter();
  
  @Input() showPeople: boolean = false;
  @Input() manageFavPeople: boolean = false;
  @Output() onRemovePerson = new EventEmitter<Person>();
  @Input() removePersonTitle: string = "Remove";
  @Output() onAddPerson = new EventEmitter<Person>();
  @Input() addPersonTitle: string = "Add";


  constructor() {}

  onExpand(unit: OrgUnit) {
    unit.expanded = !unit.expanded;
    this.onUnitExpand.emit(unit);
  }

  onSelectChange(unit: OrgUnit) {
    this.onUnitSelectChange.emit(unit);
  }

  routerLink(url: string): string {
    let l = new URL(window.location.origin + url);
    return l.pathname;
  }

  routerQuery(url: string): Params {
    let l = new URL(window.location.origin + url);
    let o: Params = {};
    for (let e of l.searchParams.entries()) {
      if (o.hasOwnProperty(e[0])) {
        if (!Array.isArray(o[e[0]]))
          o[e[0]] = [o[e[0]]];
        o[e[0]].push(e[1]);
      } else
        o[e[0]] = e[1];
    }
    return o;
  }

}