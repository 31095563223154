import { Transaction } from "./transaction";
import { Util } from "../util/util";
import { Comment } from "./comment";
import { XpDistribution } from "./xp-distribution";
import { Attachment } from "./attachment";
import { Change } from "./change";

// $$Implements SW.BE.DTO.XP
export class Expense {
    id: number = 0;
    personId: number = 0;
    loginId: string = "";
    personName: string = "";
    xprId?: number = 0;
    postdate: Date = new Date();
    vendor: string = "";
    amount: number = 0;
    debit?: number = undefined;
    credit?: number = undefined;
    reconciled: number = 0;
    disputed: boolean = false;
    trip: boolean = false;
    description: string = "";
    lineNumber: number = 0;
    xctn?: Transaction;
    editable?: boolean;
    commentable?: boolean;
    reconciler?: boolean;
    defaultAcc?: string;
    last4?: string;
    auditError?: number;
    comments?: Comment[];
    commentCount?: number;
    distributions?: XpDistribution[];
    attachments?: Attachment[];
    changes?: Change[];
    selected?: boolean;

    readonly dates: string[] = Expense.Dates;
    static readonly Dates: string[] = Util.DatePropertiesKeys(new Expense());
    static fixDates(e:Expense) {
        if (e.xctn)
            Transaction.fixDates(e.xctn);
        if (e.comments)
            e.comments.map(c=>Comment.fixDates(c));
        if (e.distributions)
            e.distributions.map(c=>XpDistribution.fixDates(c));
        if (e.attachments)
            e.attachments.map(c => Attachment.fixDates(c));
        if (e.changes)
            e.changes.map(c => Change.fixDates(c));
        return Util.FixDates(e, Expense.Dates);
    }

    public get attCount(): number {
        return this.attachments?.reduce((v,a)=>v+(a.deleted?0:1),0) ?? 0;
    }

    public get distCount(): number {
        return this.distributions?.length ?? 0;
    }

    public get att1(): string {
        let res= 'Add attachment';
        if (this.attCount)
            res = this.getAtt(0).filename;
        return res;
    }

    public getAtt(idx:number): Attachment {
        let atts= this.attachments?.filter(a=>!a.deleted);
        return atts && atts.length>idx?atts[idx]:undefined;
    }
}

