<ng-container [ngTemplateOutlet]="unitTmpl"
  [ngTemplateOutletContext]="{u:orgTree, sel:select, favs:showFavUnits, ppl:showPeople}"></ng-container>

<ng-template #unitTmpl let-u="u" let-sel="sel" let-favs="favs" let-ppl="ppl">
  <div class="d-flex" *ngIf="u.favorite || !favs">
    <span *ngIf="ppl || u.units" (click)="onExpand(u)" [attr.aria-expanded]="u.expanded">
      <i class="fa fa-angle-right fa-sm" *ngIf="!u.expanded"></i>
      <i class="fa fa-angle-down fa-sm" *ngIf="u.expanded"></i>
    </span>
    <span *ngIf="!sel">
      {{u.accessScope}}: {{u.description}}
      <sup>
        <i class="fa fa-credit-card fa-sm cursor-pointer text-success me-1" title="Expenses" [routerLink]="routerLink('/xp')"
          [queryParams]="routerQuery('?unit='+u.accessScope)"></i>
        <i class="fa fa-suitcase fa-sm cursor-pointer text-success me-1" title="Travel"
          [routerLink]="routerLink('/my-travel')" [queryParams]="routerQuery('?unit='+u.accessScope)"></i>
        <i class="fa fa-file-lines fa-sm cursor-pointer text-success me-1" title="Expense reports"
          [routerLink]="routerLink('/xpr')" [queryParams]="routerQuery('?unit='+u.accessScope)"></i>
      </sup>
    </span>
    <label *ngIf="sel" class="col-12">
      <input class="form-check-input" type="checkbox" [(ngModel)]="u.favorite" (change)="onSelectChange(u)">
      {{u.accessScope}}: {{u.description}}
    </label>
  </div>

  <div *ngIf="u.expanded" class="ms-4">
    <div *ngFor="let s of u.units | keyvalue">
      <ng-container [ngTemplateOutlet]="unitTmpl" [ngTemplateOutletContext]="{u:s.value, sel:sel, favs:favs, ppl:ppl}"></ng-container>
    </div>
    <div *ngIf="ppl && u.people===undefined"><i class="fa-solid fa-sync fa-spin"></i>Loading...</div>
    <div *ngIf="ppl && u.people && u.people.length==0 && !u.units">No people in this unit</div>
    <lib-people
      *ngIf="ppl && u.people && u.people.length"
      [people]="u.people"
      [showAddFavs]="manageFavPeople"
      (onRemovePerson)="onRemovePerson.emit($event)"
      [removeTitle]="removePersonTitle"
      (onAddPerson)="onAddPerson.emit($event)"
      [addTitle]="addPersonTitle"
    ></lib-people>
  </div>
</ng-template>

