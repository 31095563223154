<div *ngIf="expense" c>
  <div class="d-flex border border-dark border-3 fs-4">
    <div class="row flex-fill">
      <div class="col-6">{{currencyString(expense.amount)}}</div>
      <div class="col-6">{{dateString(expense.postdate)}}</div>
      <div class="col-6">{{expense.vendor}}</div>
      <div class="col-6">Line {{expense.lineNumber}}</div>
    </div>
    <icon-text [faicon]="'message'" [text]="(expense.comments.length||'+').toString()" [iconClass]="'fa-regular'" [textStyle]="'transform:translateY(-1.75rem);'" class="float-end m-1" (click)="toggleComments()"></icon-text>
  </div>

  <div *ngIf="!!expense&&showComments" class="p-1 border border-dark"
    style="max-height: 25vh; overflow-y: auto;"
  >
    Comments:
    <button class="btn btn-sm btn-success rounded-pill" (click)="onAddComment.emit(expense.id)">
      <i class="fa fa-solid fa-plus"></i>
    </button>
    <lib-comments 
      #comments
      *ngIf="!!expense"
      [apiURL]="'/api/expenses'"
      [items]="expense.comments"
      [modifiable]="expense.commentable"
      [showHistory]="false"
      (onUpdate)="onCommentsUpdate()"
    ></lib-comments>
  </div>
  
  <div class="row m-1" *ngFor="let dist of expense.distributions">
    <div class=" d-flex col-10">
      <div>
        <button class="btn btn-sm btn-danger rounded-pill fit-cnt m-1" (click)="delDist(dist)"><i class="fa-xs fa-solid fa-trash"></i></button>
      </div>
      <div>
        <div>
          <span editable [(ngModel)]="dist.account" enterBlur class="me-4"></span>
          @if (dist.slid || dist.slac) {
            <span editable enterBlur focusSelect focusOnShow [(ngModel)]="dist.slid"></span>
            /
            <span editable enterBlur focusSelect [(ngModel)]="dist.slac" maxlength="1"></span>
          } @else {
            <span (click)="dist.slid='0';dist.slac='0000000000000000'" maxlength="16" class="text-primary cursor-pointer">Add SLID/SLAC</span>
          }
        </div>
        <div style="font-size: x-small">
          @if (dist.description!=='') {
            <div editable enterBlur focusOnShow [(ngModel)]="dist.description"></div>
          } @else {
            <span class="text-primary cursor-pointer" (click)="dist.description=undefined">Add justification for this distribution (optional)</span>
          }
        </div>
      </div>
    </div>
    <div *ngIf="expense.distributions.length>1" class="col-2">
      <span *ngIf="dist.amount!==undefined" (click)="editAmount(dist)">{{currencyString(dist.amount)}}</span>
      <input *ngIf="dist.amount===undefined" 
        type="number" 
        class="w-100 amount-input"
        [(ngModel)]="draftAmount" 
        (blur)="dist.amount = this.draftAmount" 
        (keydown)="onAmountKey($event)" 
        step="0.01"
      >

    </div>
  </div>
  
  <div *ngIf="expense.distributions.length>1" class="row m-1">
    <div class="d-flex justify-content-end">
      <hr class="col-4 m-0">
    </div>
    <label class="col-10 text-end">Balance:</label>
    <div class="col-2">{{currencyString(balance)}}</div>
  </div>
  
  <div ngbDropdown class="d-inline-block m-1">
    <button class="btn btn-outline-dark" ngbDropdownToggle>Add MFK from Favorites</button>
    <div ngbDropdownMenu>
      <button ngbDropdownItem *ngFor="let a of favAccounts" (click)="addDist(a.account)" [disabled]="isAcctUsed(a.account)">
        <div class="d-flex justify-content-center">
          <span class="me-2">{{a.description}}</span>
          <span class="text-nowrap">{{a.account}}</span>
        </div>
      </button>
    </div>
  </div>

  <div class="d-flex m-1">
    <div class="text-nowrap align-self-center">or enter<br>MFK</div>
    <lib-account [(account)]="draftAcct"></lib-account>
    <button type="button" class="btn btn-success align-self-end" [disabled]="isAcctUsed(draftAcct)" [title]="isAcctUsed(draftAcct)?'Account is already used':''" (click)="addDist(draftAcct)">Apply</button>
  </div>

  <div class="d-flex m-1">
    <div class="d-flex justify-content-end flex-fill" style="scale: .8;">
      <label>SLID/SLAC:</label>
      <input type="text" [(ngModel)]="draftSlid" class="form-control-sm" style="width: 4rem;" maxlength="1">/
      <input type="text" [(ngModel)]="draftSlac" class="form-control-sm" style="width: 4rem;" maxlength="16" >
    </div>
  </div>

  
  <div class="my-1">
    <select>
      <option style="display: none;">Other options</option>
      <option>Dispute</option>
      <option>Payrol deduction</option>
    </select>
  </div>

  <div *ngIf="expense.distributions.length==1 || !balance">
    Business Justification
    <input type="text" 
      placeholder="Business justification / line description" 
      [(ngModel)]="draftJust" 
      [disabled]="disableBJ()"
      class="w-100">
    <div *ngIf="showOverrideCB()">
      <label>
      <input type="checkbox" [(ngModel)]="applyJustAll">
      Override justifications already entered</label>
    </div>
    <div class="w-100 d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-sm btn-success"
        *ngIf = "draftJust"
        (click)="applyJustBulk()"
      >{{buttonText()}}</button>
    </div>
  </div>
  
  Attachments:
  <div *ngFor="let a of expense.attachments">
    <a class="flex-grow-1 text-truncate" target="_blank"
      href="/api/expenses/att/{{a.id}}">{{a.filename}}</a>
  </div>
</div>
