<div class="d-flex m-0 h-100">
  <div id="side-nav" class="d-none d-lg-block flex-shrink-0 side-nav p-0 pt-1">
    <ul class="nav flex-column">
      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLink="home"
          title="Home"
        >
          <fa-icon
            [icon]="homeIcon"
            [fixedWidth]="true"
            aria-hidden="true"
          ></fa-icon>
          <span *ngIf="expanded">Dashboard</span>
        </a>
      </li>
      <!-- $$Implements SW.FE.Menu -->
      <li class="nav-item" *ngFor="let item of linkItems">
        <a
          class="nav-link"
          routerLinkActive="active-link"
          routerLink="{{ item.path }}"
          [title]="item.data.label"
        >
          <fa-icon
            *ngIf="item.data.icon"
            [icon]="item.data.icon"
            [fixedWidth]="true"
            aria-hidden="true"
            class="me-2"
          ></fa-icon>
          <span *ngIf="expanded">{{ item.data.label }}</span>
        </a>
      </li>
      <a class="nav-link" [title]="expanded?'Collapse':'Expand'" (click)="expanded=!expanded">
        <fa-icon [icon]="expanded?collapseIcon:expandIcon" [fixedWidth]="true" aria-hidden="true"></fa-icon>
      </a>

    </ul>

  </div>
  <div id="main-content" class="flex-fill mt-3" style="margin: 3rem 4rem 0px 4rem;">
    <router-outlet></router-outlet>
  </div>
</div>
<lib-hubSelect></lib-hubSelect>