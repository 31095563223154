import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AppEventsService, FavoritesService } from "src/app/core";
import { AppEventType } from "src/app/core/models/app-event";
import { ModalService } from "../../modal/modal.service";

@Component({
  selector: 'lib-fav-star',
  templateUrl: "./fav-star.component.html",
  styleUrls: ["./fav-star.component.scss"]
})
export class FavStar implements OnInit {
  @Input() descr: string;

  added: boolean = false;

  constructor(
    private router: Router,
    private favSvc: FavoritesService,
    private modal: ModalService,
    private aeBus: AppEventsService
  ) {}

  ngOnInit() {
    this.update();
    this.aeBus.on(AppEventType.UrlUpdated)
      .subscribe(e => this.update());
  }

  update() {
    this.added = this.favSvc.isUrlAdded(this.url);
  }
  
  get url():string {
    let loc = window.location;
    return `${loc.pathname}${loc.hash}${loc.search}`;
  }
  public get iconClass(): string {
    return "ms-3 "+(this.added ? "fa-solid":"fa-regular");
  }

  public get title(): string {
    return this.added?"Already in your favorits":"Add to your favorits";
  }

  @ViewChild("favDescTmpl") favDescTmpl: TemplateRef<any>;
  onClick() {
    if (this.added){
      this.router.navigate(['my-links'])
      return;
    }
    this.modal.show({
      title:"Add to favorites",
      cancelBtn: "Cancel",
      template: this.favDescTmpl,
      okOnEnter: true
    }).subscribe(
      res=>{
        if (!res)
          return;
        this.favSvc.addLink({
          url: this.url,
          description: this.descr
        }).subscribe(
          res => {
            this.added = true;
          }
        );
    });
  }
}
