import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, ViewEncapsulation, AfterViewInit, ViewContainerRef } from '@angular/core';
import { DatatableComponent as NgxDatatableComponent, TableColumn, SortPropDir, SelectionType, ColumnMode, SortDirection } from '@siemens/ngx-datatable';
import { DatasetCommand, Dataset } from 'src/app/core/models/dataset';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Util } from 'src/app/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { DateRange, DateRangeKind } from '../date-range/date-range.component';

export type FilterChoices = { [key: string]: (string | [string, string])[] };
export type RowSelector = (row:any) => boolean;

export interface TableState {
  OrderBy?: string;
  Filter?: string[];
  Offset?: number;
  Select?: (v:any)=>boolean;
}

@Component({
  selector: 'shared-datatable',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit, AfterViewInit {
  dateFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' })
  dateTimeFormat = new Intl.DateTimeFormat('en', { timeStyle: 'short', dateStyle: 'short' })

  @Input() 
    get columns(): TableColumn[] {return this._columns}
  set columns(c:TableColumn[]) {
    this.updateTemplates(c);
    this._columns=[...c];
  }
  _columns: TableColumn[];
  @Input() serverSide = true;
  @Input() limit = undefined;
  @Input() selectionType: SelectionType = SelectionType.single;
  @Input() selected:any[] = [];
  @Input() displayCheck: (row,column,value)=>boolean = undefined;
  @Input() rowClass: any;
  @Input() columnMode: ColumnMode = ColumnMode.standard;
  @Input() sorts: SortPropDir[] = [];
  @Input() filter: { [key: string]: string } = {};
  @Input() filterChoices: FilterChoices = {};
  @Input() computedChoices: string[] = [];
  @Input() defaultRow: any;
  @Input() getData: (cmd:DatasetCommand)=>Observable<Dataset<any>>;
  @Input() getAllData: () => Observable<any[]>;
  
  @Input() rowHeight: number = 40;
  @Input() headerHeight: number = 70;
  @Input() footerHeight: number = 50;
  @Input() scrollbarV: boolean = true;
  @Input() scrollbarH: boolean = true;
  @Input() rowDetailTemplate: TemplateRef<any>;
  @Input() rowDetailHeight: number | ((row?: any, index?: number) => number) = 0;

  @Input() tableClass: string = "bootstrap"
  @Output() activate = new EventEmitter<any>();
  @Output() view = new EventEmitter<any>();
  @Output() filterCell = new EventEmitter<any>();
  @Output() select = new EventEmitter<any>();
  @Output() sort = new EventEmitter<any>();
  @Output() afterInit = new EventEmitter<DatatableComponent>();

  @ViewChild('datatable') datatable: NgxDatatableComponent;
  @ViewChild('textSearch', { static: true }) textSearch: TemplateRef<any>;
  @ViewChild('selectSearch', { static: true }) selectSearch: TemplateRef<any>;
  @ViewChild('dateSearch', { static: true }) dateSearch: TemplateRef<any>;
  @ViewChild('dateRangeSearch', { static: true }) dateRangeSearch: TemplateRef<any>;
  @ViewChild('boolSearch', { static: true }) boolSearch: TemplateRef<any>;
  @ViewChild('dateColumn', { static: true }) dateColumn: TemplateRef<any>;
  @ViewChild('dateTimeColumn', { static: true }) dateTimeColumn: TemplateRef<any>;
  @ViewChild('boolColumn', { static: true }) boolColumn: TemplateRef<any>;
  @ViewChild('arrayLengthColumn', { static: true }) arrayLengthColumn: TemplateRef<any>;
  @ViewChild('filterCell', { static: true }) filterCellTmpl: TemplateRef<any>;
  @ViewChild("viewCellTemplate", { static: true }) viewCellTemplate: TemplateRef<any>;
  
  data: any[];
  rows: any[];
  cache: any = {};
  isLoading = 0;
  total = 0;
  idx = 0;
  cmd: DatasetCommand = {
    First: 1,
    MaxNumber: 25
  };

  scrollSubj = new Subject();
  scrollPipe$: Observable<any>;

  constructor(
  ) { 
  }

  ngOnInit(): void {
    // seed some data to fix broken sizing
    if (this.serverSide)
      setTimeout(()=>this.rows = Array.from({length:40},()=>this.defaultRow));
    this.updateFilterChoices();
    this.updateTemplates(this._columns);
    this.updateFilterCommand();
    this.updateOrderByCmd();
    
    this.scrollPipe$ = this.scrollSubj.pipe(
      debounceTime(50),
      switchMap((pageInfo:any)=>{
        this.idx = pageInfo.offset;
        this.cmd.MaxNumber = pageInfo.pageSize;
        this.loadData();
        return of(pageInfo.offset);
      })
    );
    this.scrollPipe$.subscribe();
    setTimeout(() => {
      if (this.serverSide)
        this.updateFilterElements();
      else
      if (this.loadAllData)
        this.loadAllData();
    })
  }

  ngAfterViewInit(): void {
    this.afterInit.emit(this);
  }
  updateFilterChoices() {
    Object.keys(this.filterChoices).forEach(k=>
      this.filterChoices[k].forEach((v,i,a)=>{
        if (typeof(v)=="string")
          a[i] = [v,v];
      }));
  }

  updateTemplates(columns:TableColumn[]) {
    let templates = {
      textSearch: this.textSearch,
      selectSearch: this.selectSearch,
      dateSearch: this.dateSearch,
      dateRangeSearch: this.dateRangeSearch,
      boolSearch: this.boolSearch,
      dateColumn: this.dateColumn,
      dateTimeColumn: this.dateTimeColumn,
      boolColumn: this.boolColumn,
      arrayLengthColumn: this.arrayLengthColumn,
      filterCell: this.filterCellTmpl,
      viewCell: this.viewCellTemplate,
    }
    columns.forEach(c => {
      if (c.headerTemplate && typeof c.headerTemplate == "string") {
        if (c.headerTemplate == "dateRangeSearch")
          this.ranges[c.prop] = { rangeKind: DateRangeKind.after }
        c.headerTemplate = templates[c.headerTemplate];
      }
      if (c.cellTemplate && typeof c.cellTemplate == "string")
        c.cellTemplate = templates[c.cellTemplate];
    })
  }

  updateFilterElements() {
    Object.keys(this.filter).forEach(k => {
      let e: any = this.datatable.element.querySelector(`[name="${k}"]`);
      if (e)
        e.value = this.filter[k];
    });
  }

  applyFilters() {
    let o = { ...this.defaultRow };
    let temp = [...this.data];
    Object.keys(this.filter).forEach(p=>{
      let strval = this.filter[p];
      let val:number|string|boolean|Date;
      let t = typeof o[p];
      let pred;
      if (t == 'number' || t == 'object') {
        if (strval.startsWith('<='))
          pred = (a, b) => a <= b, strval = strval.substr(2);
        else if (strval.startsWith('<'))
          pred = (a, b) => a < b, strval = strval.substr(1);
        else if (strval.startsWith('>='))
          pred = (a, b) => a >= b, strval = strval.substr(2);
        else if (strval.startsWith('>'))
          pred = (a, b) => a > b, strval = strval.substr(1);
        else {
          pred = (a, b) => a == b;
          if (strval.startsWith('='))
            strval = strval.substr(1);
        }
        if (t == 'number')
          val = Number.parseFloat(strval);
        else
          val=new Date(strval);
      }
      else if (t == 'boolean') {
        if (strval.startsWith('='))
          strval = strval.substr(1);
        val=strval=="true";
        pred = (a,b) => a == b;
      } else if (t == 'string') {
        if (strval.startsWith('='))
          pred = (a: string, b: string) => a.toUpperCase() == b.toUpperCase(), val = strval.substr(1);
        else
          pred = (a: string, b: string) => a.toUpperCase().includes(b.toUpperCase()), val=strval;
      }
      if (!pred) {
        throw new Error("Unknown datatable filter")
      }
      temp = temp.filter(e=>pred(e[p],val));
    })
    this.rows = [...temp];
  }

  loadAllData() {
    this.isLoading=1;
    this.getAllData()
      .subscribe((data: any[]) => {
        this.isLoading=0;
        this.data = [...data];
        this.computedChoices.forEach(f=>{
          this.filterChoices[f] = [...new Set(data.map(e => e[f]))].sort();
        });
        if (this.computedChoices.length)
          this.updateFilterChoices();
        this.applyFilters();
        setTimeout(()=>this.updateFilterElements());
      });
  }

  setSelected(selected:any[]) {
    this.datatable.selected = this.selected = selected;
  }

  private doPenfingSelect() {
    if (this.PendingSelect)
      this.selectRow(this.PendingSelect);
    else
      this.datatable.selected = this.selected; // seems a bug in angular updating this input in datatable
    //this.PendingSelect = null;
  }

  loadPage() {
    this.isLoading++;
    this.rows = [];
    this.cmd.First = this.cmd.MaxNumber * this.idx || 1;
    let cmd = { ...this.cmd };
    this.getData(cmd)
      .subscribe((dataset: Dataset<any>) => {
        this.total = dataset.total;
        this.rows = dataset.data;
        this.isLoading--;
        this.doPenfingSelect();
      });
  }

  loadData(offset?:number) {
    if (!this.serverSide)
      return;
    if (this.limit)
      return this.loadPage();
    let first = Math.max(this.idx-1, 1);
    let indexes = [first,first+1,first+2]
    // don't load same data twice
    if (indexes.every(i=>this.cache[i]))
      return;
    indexes.forEach(i => this.cache[i] = true);
    this.isLoading++;
    this.cmd.First = this.cmd.MaxNumber*this.idx||1;
    let cmd = {...this.cmd};
    cmd.First = Math.max(cmd.First - cmd.MaxNumber,1);
    cmd.MaxNumber *= 3;
    this.getData(cmd)
    .subscribe((dataset: Dataset<any>) => {
      this.total = dataset.total;
      if (!this.rows) {
        // length should be total count
        this.rows = new Array<any>(dataset.total || 0);
      }
      if (this.rows.length != dataset.total) {
        this.cache={};
        this.rows = new Array<any>(dataset.total || 0);
      }
      const rows = [...this.rows];
      rows.splice(cmd.First-1, dataset.data.length, ...dataset.data);
      this.rows = rows;
      this.isLoading--;
      this.doPenfingSelect();
      if (offset) setTimeout(()=>{
        this.datatable.offset = offset;
        this.restoreScroll();
      });
    },
    error=>{
      this.isLoading--;
      throw error;
    });
  }

  resetTable(force?:boolean) {
    this.datatable.element.getElementsByTagName('datatable-body')[0].scrollTop = 0;
    this.datatable.offset = 0;
    this.cmd.First=1;
    this.selectRow(null);
    this.cache = {};
    this.total = 0;
    this.idx = 1;
    if (this.serverSide)
      this.loadData();
    else if (force)
      this.loadAllData();
  }

  setPage(pageInfo) {
    this.scrollSubj.next(pageInfo);
  }

  onSort(event) {
    const sort = event.sorts[0];
    this.sorts = [{ prop:sort.prop, dir:sort.dir}]
    this.updateOrderByCmd();
    if (this.serverSide)
      this.resetTable();
    this.sort.emit(event);
  }

  onActivate(event:any) {
    if (event.type != "click")
      return;
    if (event.event.target.tagName == 'INPUT') {
      return;
    }

    if (event.event.target.tagName == 'I' && event.event.target.classList.contains('fa-filter')) {
      this.filterCell.emit(event);
      return;
    }
    if (event.event.target.tagName == 'I' && event.event.target.classList.contains('fa-eye')) {
      this.view.emit(event);
      return;
    }
    this.activate.emit(event);
  }
  onSelect(event:any) {
    this.select.emit(event);
  }
  
  onHeaderClick(event) {
    if (event.currentTarget.className != "datatable-header-cell-wrapper" || event.currentTarget != event.target)
      return;
    event.currentTarget.nextElementSibling.click();
  }

  filterFor(p: string): string[] {
    let o = {...this.defaultRow};
    let t = typeof o[p];
    let name = p;
    let val = this.filter[p];
    if (t == 'number' || t == 'object') {
      if (val.startsWith('='))
        val = val.substring(1);
      else if (val.startsWith('!'))
        name = name + "!", val = val.substring(1);
      else if (val.startsWith('<='))
        name = name + "<=", val = val.substring(2);
      else if (val.startsWith('<'))
        name = name + "<", val = val.substring(1);
      else if (val.startsWith('>='))
        name = name + ">=", val = val.substring(2);
      else if (val.startsWith('>'))
        name = name + ">", val = val.substring(1);
      else if (val.search('<')>0) {
        let idx = val.search('<');
        let val1 = val.substring(0,idx);
        let val2 = val.substring(idx+1);
        return [`${name}>=~${val1}`, `${name}<=~${val2}`];
      }
    }
    else if (t == 'boolean') {
      if (val.startsWith('='))
        val = val.substring(1);
      return [`${(val == 'true') ? '' : '!'}${name}`];
    } else if (t == 'string') {
      if (val.startsWith('=@')) { // predicate
        return [val.substring(2)];
      }
      if (val.startsWith('='))
        name = name + '=', val = val.substring(1);
    }
    return [`${name}~${val}`];
  }

  updateFilterCommand() {
    let filter: string[] = [];
    Object.keys(this.filter).forEach(k => filter.push(...this.filterFor(k)));
    if (filter.length == 0)
      delete this.cmd.Filter;
    else
      this.cmd.Filter = filter;
  }

  readonly ops = ['<=','<','>','>=','=','!'];
  filterFromUrlParam(p:string) {
    let [name,val]=p.split('~');
    let op = '';
    for (const o of this.ops) {
      if (name.endsWith(o)) {
        name = name.substring(0,name.length-o.length);
        op = o;
        break;
      }
    }
    let o = { ...this.defaultRow };
    if (!o.hasOwnProperty(name)) {
      console.log(`requested filter for unknown property ${name}`);
      return false;
    }
    let t = typeof o[name];
    let filter = "";
    if (t == 'number' || t == 'object') {
      if (this.filterChoices.hasOwnProperty(name) && ! op)
        op = '=';
      filter=op+val;
    }
    else if (t == 'boolean') {
      filter = (op=='!')?'false':'true';
    } else if (t == 'string') {
      filter = op + val;
    }
    if (this.filter.hasOwnProperty(name)) {
      let filt1 = this.filter[name];
      if (filt1==filter)
        return false;
      // check for range filter
      if (op=='>='&&filt1.startsWith('<='))
        filter=val+'<'+filt1.substring(2);
      else if (op=='<='&&filt1.startsWith('>='))
        filter = filt1.substring(2)+'<'+val;
    }
    this.filter[name]=filter;
    this.updateFilterCommand();
    this.updateFilterElements();
    return true;
  }

  sortFromUrlParam(p:string) {
    let [prop,ord]=p.split('.');
    let o = { ...this.defaultRow };
    if (!o.hasOwnProperty(prop)) {
      console.log(`requested sorter for unknown property ${prop}`);
      return false;
    }
    let sorts = [{ prop, dir: (ord == 'desc') ? SortDirection.desc : SortDirection.asc }]; 
    if (Util.arraysEqual(this.sorts, sorts))
      return false;
    this.sorts=sorts;
    this.updateOrderByCmd();
    return true;
  }

  updateOrderByCmd() {
    if (this.sorts.length)
      this.cmd.OrderBy = `${this.sorts[0].prop}.${this.sorts[0].dir}`;
  }

  getState():TableState {
    let result = {Offset:this.datatable.offset,...this.cmd};
    delete result.First;
    delete result.MaxNumber;
    return result;
  }

  private PendingSelect?: RowSelector = null;

  restoreScroll() {
    this.datatable.bodyComponent.updateOffsetY(this.datatable.offset);
  }

  getOffset() {
    return this.datatable.offset;
  }

  setOffset(o:number) {
    this.datatable.offset = o;
  }

  getSelectedRow() {
    if (this.datatable.selected.length==1)
      return this.datatable.selected[0];
    else
      return undefined;
  }

  restoreState(state: TableState, selector?: RowSelector) {
    let updated = false;
    let filter = state['Filter'];
    if (filter) {
      if (Array.isArray(filter))
        filter.forEach(f => updated = this.filterFromUrlParam(f) || updated);
      else
        updated = this.filterFromUrlParam(filter);
    }
    let sorts = state['OrderBy'];
    if (sorts)
      updated = this.sortFromUrlParam(sorts) || updated;
    let offset = state['Offset']||0;
    if (this.serverSide) {
      this.PendingSelect = selector;
      if (updated || !this.rows)
        this.loadData(offset);
      else {
        this.doPenfingSelect();
        this.datatable.offset = offset;
        this.restoreScroll();
      }
    } else {
      if (sorts) {
        this.datatable.onColumnSort({ sorts: this.sorts });
      }
      let sel = selector ? this.datatable._internalRows.filter(row=>selector(row)): null;
      if (sel) {
        this.selected=[...sel];
        let idx = this.datatable._internalRows.findIndex(v=>v===sel[0]);
        offset = Math.floor(idx / this.datatable.pageSize);
        setTimeout(()=>this.setSelected(this.selected));
      }
      this.datatable.offset = offset;
      this.restoreScroll();
      if (!offset && this.rows)
        this.rows = [...this.rows];
    }
      
  }

  onSearch(event) {
    let prop = event.target.name;
    if (event.target.value)
      this.filter[prop] = event.target.value;
    else
      delete this.filter[prop];
    if (this.serverSide) {
      this.updateFilterCommand();
      this.resetTable();
    } else
      this.applyFilters();
  }

  onClearFilter(event: MouseEvent) {
    let i = (<Element>event.currentTarget).parentElement.querySelector("input");
    i.value = null;
    this.onSearch({target:i,value:null});
    i.blur();
  }

  onDateSelect(name, d) {
    let event = {
      target: {
        name,
        value: `${d.year}/${d.month}/${d.day}`
      }
    };
    this.onSearch(event);
  }

  ranges:{[key:string]:DateRange} = {};

  public rangeSelected(name:string):boolean {
    return this.ranges[name]?.rangeKind == DateRangeKind.range && !!this.ranges[name]?.toDate;
  }

  ensureDimensions() {
    // I have no idea why 3 calls are needed
    setTimeout(() => {
      this.datatable.recalculate();
      setTimeout(() => {
        this.datatable.recalculate();
        setTimeout(() => {
          this.datatable.recalculate();
        })
      })
    })
  }

  selectRow(selector?:RowSelector) {
    if (!selector) {
      this.datatable.selected = this.selected=[];
      return;
    }
    let sel = this.rows.find(selector);
    if (sel)
      this.datatable.selected = this.selected = [sel];
  }

  getRowAtIndex(idx:number) {
    let res = this.datatable._internalRows[idx+Number(this.datatable.bodyComponent.indexes.first)];
    return res;
  }
}
