import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { ModalService } from "../../modal/modal.service";
import { Mfk, MfkString, areEqual, emptyMfk, stringify } from "@uiowa/uiowa-mfk";
import { FavAccount } from "src/app/core/models/fav-account";

@Component({
  selector: 'lib-account',
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"]
})
export class Account implements OnInit, OnChanges {
  @Input() account: string
  @Output() accountChange = new EventEmitter<string>();
  @Output() OnFavSelect: EventEmitter<any> = new EventEmitter();

  constructor(
    private modal: ModalService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.account && 
      changes.account.currentValue !== undefined &&
      !areEqual(changes.account.currentValue, changes.account.previousValue)) {
      let s = new MfkString(this.account)
      if (s.isValidMfk)
        this.mfk = s.mfk
    }
  }

  mfk = emptyMfk();

  ngOnInit(): void {
    this.savedModal = undefined;
    if (this.account !== undefined){
      let s = new MfkString(this.account)
      if (s.isValidMfk)
        this.mfk = s.mfk
    }
  }

  static mfkRE = /^\d{3}-\d{2}-\d{4}-\d{5}-\d{8}-\d{4}-\d{3}-\d{5}-\d{2}-\d{4}(-\d{2})?$/
  validateMfkStructure(mfk:string) {
    return mfk.replace(Account.mfkRE,'') == ''
  }

  onChange(mfk: Mfk) {
    var s = stringify(this.mfk)
    if (this.validateMfkStructure(s))
      setTimeout(() => {
          this.accountChange.emit(s)
      })
  }

  newFavorite: string;
  savedModal: any;
  @ViewChild("favAccntsDlg") favAccntsDlg: TemplateRef<any>;
  onFavClick() {
    this.newFavorite = undefined;
    if (this.modal.modalEl) {
      this.modal.modalEl.hidden = true;
      this.savedModal = this.modal.modalEl;
      this.modal.modalEl = undefined;
    }
    this.modal.show({
      title:"Select favorite account",
      okBtn: "Cancel",
      template: this.favAccntsDlg,
      elementClass:"wide"
    })
    .subscribe();
  }

  onFavSelect(acct:string) {
    if (this.validateMfkStructure(acct)) {
        this.mfk = new MfkString(acct).mfk
    }
    this.modal.close();
    if (this.savedModal) {
      this.modal.modalEl = this.savedModal;
      this.savedModal = undefined;
      this.modal.modalEl.hidden = false;
    }
  }

  onAddFavClick() {
    this.newFavorite = this.account;
    this.modal.show({
      title: "Add favorite account",
      okBtn: "Cancel",
      template: this.favAccntsDlg,
      elementClass: "wide"
    })
      .subscribe();
  }
}